import React from 'react';
import {Link} from "react-router-dom";

class CallToAction extends React.Component {
    render() {
        return (
            <div className="cta">
                <p className="cta__text">{this.props.ctaPara}</p>
                <Link className="cta__link" to={this.props.ctaUrl}>{this.props.ctaBtnText}</Link>
            </div>
        )
    }
}

export default CallToAction
