import React from 'react';
import cover from '../images/illustration/wide/mice-wide.jpg';
import coverReduced from '../images/illustration/wide/mice-wide.avif';
import IntroPara from "../components/IntroPara";

function About() {
    return (
        <>
            <div className="about">
                <picture className="about__image">
                    <source srcSet={coverReduced} type="image/avif" />
                    <img alt="cover illustration" src={cover}/>
                </picture>
                <div className="about__content">
                    <p>
                        Hi, my name is Hannah and I am a web developer and illustrator living with my family in the outskirts of Boston. I enjoy drinking coffee and creating playful designs and illustrations whether it is on the web or while drawing on my trusted iPad.
                    </p>
                    <p>Feel free to contact me about projects or say hello: <a href="mailto:hannahwmoore@gmail.com">hannahwmoore@gmail.com</a></p>
                </div>
            </div>



        </>
    );
}

export default About;
