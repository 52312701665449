import * as React from "react";

import { NavLink } from 'react-router-dom';

class DesktopNav extends React.Component {
    render() {
        return (
            <div className="desktop-nav">
                <ul className="desktop-nav__list">
                    <NavLink className="desktop-nav__list__item" to="/"><li>Home</li></NavLink>
                    <NavLink className="desktop-nav__list__item" to="/illustration"><li>Illustration</li></NavLink>
                    <NavLink className="desktop-nav__list__item" to="/development"><li>Development</li></NavLink>
                    {/*<NavLink className="desktop-nav__list__item" to="/work-history"><li>Work History</li></NavLink>*/}
                    <NavLink className="desktop-nav__list__item" to="/about"><li>About</li></NavLink>
                </ul>
            </div>
        )
    }

}

export default DesktopNav
