
import * as React from "react";
import {
  Routes,
  Route,
  Outlet,
  useLocation
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Home from "./routes/Home";
import Illustration from "./routes/Illustration";
import Development from "./routes/Development";
import About from "./routes/About";
import Footer from "./components/Footer";
import ErrorPage from "./routes/ErrorPage";
import ScrollToTop from "./ScrollToTop";
import './app.scss';
import Header from "./components/Header";

function App() {
    const location = useLocation();

    return (
        <>
            <Header/>
          <Outlet />

            {/*<TransitionGroup component={null}>*/}
            {/*    <CSSTransition*/}
            {/*            key={location.key}*/}
            {/*            timeout={550}*/}
            {/*            classNames='fade'*/}
            {/*            >*/}
            {/*        <ScrollToTop>*/}
            {/*        <Routes location={location}>*/}
            {/*            <Route path="*" element={<ErrorPage />} />*/}
            {/*            <Route exact path="/" element={<Home />} />*/}
            {/*            <Route path="illustration" element={<Illustration />} />*/}
            {/*            <Route path="development" element={<Development />} />*/}
            {/*            /!*<Route exact path="work-history" element={<WorkHistory />} />*!/*/}
            {/*            <Route path="about" element={<About />} />*/}
            {/*        </Routes>*/}
            {/*        </ScrollToTop>*/}
            {/*    </CSSTransition>*/}
            {/*</TransitionGroup>*/}

            <Footer year={new Date().getFullYear()} />
        </>
    );
}

export default App;
