import * as React from "react";
import { NavLink } from 'react-router-dom';

class MobileNav extends React.Component {

    closeMenu = () => {
        this.props.onClickNavLink();
    }

    render() {
        let mobileSlideout;


        if (this.props.mobileToggle === true && this.props.mobileView === true) {
            mobileSlideout = "mobile-nav--slide-out"

        } else {
            mobileSlideout = "";
        }

        return (
            <div className={`mobile-nav ${mobileSlideout}`} onClick={this.closeMenu}>

                <ul className="mobile-nav__list">

                    <NavLink className="mobile-nav__list__item" to="/"><li>Home</li></NavLink>
                    <NavLink className="mobile-nav__list__item" to="/illustration"><li>Illustration</li></NavLink>
                    <NavLink className="mobile-nav__list__item" to="/development"><li>Development</li></NavLink>
                    {/*<NavLink className="mobile-nav__list__item" to="/work-history"><li>Work History</li></NavLink>*/}
                    <NavLink className="mobile-nav__list__item" to="/about"><li>About</li></NavLink>

                </ul>
            </div>
        )
    }
}

export default MobileNav
