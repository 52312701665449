import React from "react";

class PortfolioItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = () => {
        this.props.onShowDrawerChange(this.props.showDrawer, this.props.identifier, this.props.drawerTitle, this.props.drawerText, this.props.drawerUrl, this.props.drawerImages);
    }

    render() {
        return (
            <>
                <div
                    onClick={this.handleChange}
                    className="grid__item"
                    id={this.props.uniqueID}
                    data-identifier={this.props.identifier}
                >
                  {this.props.categories ?
                   <div className="grid__categories">
                        <i className={`grid__category-${this.props.categories[0]}`} title={this.props.categories[0]}></i>
                        {this.props.categories[1] ?
                            <i className={`grid__category-${this.props.categories[1]}`} title={this.props.categories[1]}></i>
                                :null}
                   </div>
                    :null}
                    <picture className="grid__item-image">
                      <source srcSet={this.props.imgsrcavif} type="image/avif"/>
                        <img loading="lazy" alt={this.props.imgalt} src={this.props.imgsrc} />
                    </picture>
                    <h2 className="grid__item-title">{this.props.title}</h2>
                </div>
            </>
        )
    }
}


export default PortfolioItem
