import * as React from "react";
import logo from "../images/logo.jpg";
import Nav from "./navigation/Nav";
import { Link } from 'react-router-dom';


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileToggle: false
        };
    }

    toggleMobileMenu = () => {
        this.setState(prevState => ({
            mobileToggle: !prevState.mobileToggle
        }))
    }

    render() {
        return (
            <div className="header">
                <Link to="/">
                    <img alt="" src={logo} className="header__logo" />
                    <h1 className="header__title">Hannah Moore</h1>
                </Link>
                <Nav toggleMobileMenu={this.toggleMobileMenu}/>
            </div>
        )
    }

}

export default Header
