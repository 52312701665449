import React from 'react';
import {homeData} from "../homeexamplesdata";
import PortfolioItem from "../components/PortfolioItem";
import Drawer from "../components/Drawer";
import { CSSTransition } from "react-transition-group";
import OutsideClickHandler from "../components/OutsideClickHandler";

const ComponentActions = {
    None: "None",
    ClickInside: "ClickInside",
    ClickOutside: "ClickOutside",
    FocusInside: "FocusInside",
    FocusOutside: "FocusOutside"
};

class Home extends React.Component {
    curDrawer;

    constructor(props) {
        super(props);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.state = { showDrawer: false, identifier: '', drawerTitle: '', drawerText: '', drawerUrl: '', drawerImages: '', clickStatus: ComponentActions.None, focusStatus: ComponentActions.None};
    }

    toggleDrawer(showDrawer, identifier, drawerTitle, drawerText, drawerUrl, drawerImages) {
        if ((this.state.showDrawer === true) && (this.curDrawer === identifier)) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
            this.setState({showDrawer: false });
            return;
        }

        if (this.state.showDrawer === false) {
            this.setState({showDrawer: true })
        }
        this.setState({
            identifier: identifier,
            drawerTitle: drawerTitle,
            drawerText: drawerText,
            drawerUrl: drawerUrl,
            drawerImages: drawerImages
        })

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })

        this.curDrawer = identifier
    }

    closeDrawer = () => {
        this.setState({showDrawer: false })
    }

    render () {
        const identifier = this.state.identifier;
        const drawerTitle = this.state.drawerTitle;
        const drawerText = this.state.drawerText;
        const drawerUrl = this.state.drawerUrl;
        const drawerImages = this.state.drawerImages;
        const showDrawer = this.state.showDrawer;

        return (
            <>
                <main>
                    <OutsideClickHandler
                        onClickOutside={() => {
                            this.closeDrawer();
                        }}
                        onFocusOutside={() => {
                            this.closeDrawer();
                        }}
                    >
                        <CSSTransition
                            in={showDrawer}
                            timeout={{
                                enter: 0,
                                exit: 500
                            }}
                            unmountOnExit
                        >
                            <Drawer
                                identifier={identifier}
                                drawerTitle={drawerTitle}
                                drawerText={drawerText}
                                drawerUrl={drawerUrl}
                                drawerImages={drawerImages}
                                showDrawer={showDrawer}
                                onClose={this.closeDrawer}
                            />
                        </CSSTransition>
                        <div className="grid">
                            {homeData.map((data) => {
                                return (
                                    <>
                                        <PortfolioItem
                                            key={data.id}
                                            identifier={data.identifier}
                                            drawerTitle={data.drawerTitle}
                                            drawerText={data.drawerText}
                                            drawerUrl={data.drawerUrl}
                                            drawerImages={data.drawerImages}
                                            onShowDrawerChange={this.toggleDrawer}
                                            title={data.title}
                                            imgsrc={data.imgsrc}
                                            imgsrcavif={data.imgsrcavif}
                                            imgalt={data.imgalt}
                                            categories={data.categories}
                                        />
                                    </>
                                );
                            })}
                        </div>
                    </OutsideClickHandler>
                </main>
            </>
        );
    }
}

export default Home;
