export const devData = [{
    uniqueID: "commencement",
    identifier: "commencement",
    title: "Northeastern Commencement",
    imgsrc: require("./images/development/front/commencement-front.jpg"),
    imgsrcavif: require("./images/development/front/commencement-front.avif"),
    imgalt: "Photo collage of Northeastern University Commencement 2022",
    drawerTitle: "Northeastern Commencement 2022",
    drawerText: "I have been a pivotal part of Northeastern University's commencement webpage for the past several years. This once-a-year webpage project requires design and development as well as on-site coding and updates while the event is going on to ensure that more than 4,000 graduates and their families have constant access to information about the event.",
    drawerUrl: "https://news.northeastern.edu/2022/05/12/commencement-2022/",
    drawerImages: [
        {
            src: require("./images/development/wide/commencement-wide.jpg"),
            avifsrc: require("./images/development/wide/commencement-wide.avif"),
            alt: "screenshot of the Northeastern Commencement 2022 webpage",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/development/narrow/commencement-narrow.jpg"),
            avifsrc: require("./images/development/narrow/commencement-narrow.avif"),
            alt: "mobile device mockup of the Northeastern Commencement 2022 webpage",
            class: "drawer-image__phone--tilted"
        },
        {
            src: require("./images/development/narrow/commencement2-narrow.jpg"),
            avifsrc: require("./images/development/narrow/commencement2-narrow.avif"),
            alt: "mobile device mockup of the Northeastern Commencement 2022 webpage",
            class: "drawer-image__phone"
        },
    ]
},
    {
        uniqueID: "binary",
        identifier: "binary",
        title: "Interactive Article: Outside of the Binary",
        imgsrc: require("./images/development/front/binary-portraits-front.jpg"),
        imgsrcavif: require("./images/development/front/binary-portraits-front.avif"),
        imgalt: "Portraits of people who identify as binary",
        drawerTitle: "Outside of the Binary: Northeastern's Gender Non-conforming Community",
        drawerText: "This interactive storytelling project is a portrait series presenting intimate portraits of Northeastern community members who are transgender, nonbinary, and gender nonconforming.",
        drawerUrl: "https://news.northeastern.edu/2021/11/01/trans-gender-and-gender-nonconforming-community-portraits/",
        drawerImages: [
            {
                src: require("./images/development/wide/binary-portraits-wide.jpg"),
                avifsrc: require("./images/development/wide/binary-portraits-wide.avif"),
                alt: "Webpage layout showing text next to a photo of a person",
                class: "drawer-image__wide"
            },
            {
                src: require("./images/development/binary-portrait-movement.gif"),
                alt: "a gif of a person looking up",
                class: "drawer-image__gif"
            },
            {
                src: require("./images/development/narrow/binary-narrow.jpg"),
                avifsrc: require("./images/development/narrow/binary-narrow.avif"),
                alt: "A mobile device layout showing text below a photo of a person",
                class: "drawer-image__phone"
            },
        ]
    },
    {
        uniqueID: "canvas",
        identifier: "canvas",
        title: "Canvas games",
        imgsrc: require("./images/development/front/grad-game-front.jpg"),
        imgsrcavif: require("./images/development/front/grad-game-front.avif"),
        imgalt: "alt text",
        drawerTitle: "Canvas games",
        drawerText: "",
        drawerImages: [
            {
                src: require("./images/development/wide/pesky-squirrel-2-wide.jpg"),
                avifsrc: require("./images/development/wide/pesky-squirrel-2-wide.avif"),
                alt: "alt text",
                class: "drawer-image__wide",
                url: "https://news.northeastern.edu/2019/10/08/play-pesky-squirrels-have-hidden-your-books-on-northeasterns-boston-campus-arboretum/"
            },
            {
                src: require("./images/development/square/grad-game-gameplay-square.jpg"),
                avifsrc: require("./images/development/square/grad-game-gameplay-square.avif"),
                alt: "alt text",
                class: "drawer-image__square",
                url: "https://news.northeastern.edu/2019/05/02/play-this-game-to-see-if-you-can-earn-your-northeastern-university-diploma/"
            },
            {
                src: require("./images/development/square/grad-game-end-square.jpg"),
                avifsrc: require("./images/development/square/grad-game-end-square.avif"),
                alt: "alt text",
                class: "drawer-image__square",
                url: "https://news.northeastern.edu/2019/05/02/play-this-game-to-see-if-you-can-earn-your-northeastern-university-diploma/"
            },
            {
                src: require("./images/development/square/snowflake-square.jpg"),
                avifsrc: require("./images/development/square/snowflake-square.avif"),
                alt: "alt text",
                class: "drawer-image__square",
                url: "https://news.northeastern.edu/2020/12/16/have-some-fun-during-the-new-england-blizzard-while-staying-warm-inside-with-some-snow/"
            },
            {
                src: require("./images/development/narrow/hangman-narrow.jpg"),
                avifsrc: require("./images/development/narrow/hangman-narrow.jpg"),
                alt: "alt text",
                class: "drawer-image__phone--tilted",
                url: "https://news.northeastern.edu/2018/09/12/how-well-do-you-know-northeastern-test-your-knowledge-with-this-interactive-game/"
            },
        ]
    },
    {
        uniqueID: "black-artists",
        identifier: "black-artists",
        title: "Interactive Article: Black Artists in Boston",
        imgsrc: require("./images/development/front/black-artists-front.jpg"),
        imgsrcavif: require("./images/development/front/black-artists-front.avif"),
        imgalt: "portraits of black artists in Boston",
        drawerTitle: "Black Artists",
        drawerText: "Combining illustration and web development to give the user a more immersive experience in this piece focused on black artists in Boston.",
        drawerUrl: "https://news.northeastern.edu/2022/02/09/black-artists-boston-public-art/",
        drawerImages: [
            {
                src: require("./images/development/wide/black-artists-wide.jpg"),
                avifsrc: require("./images/development/wide/black-artists-wide.avif"),
                alt: "map of Boston with a pin indicating where a sculpture by Hank Willis Thomas will be located",
                class: "drawer-image__wide"
            },
            {
                src: require("./images/development/square/black-artists-square.jpg"),
                avifsrc: require("./images/development/square/black-artists-square.avif"),
                alt: "portraits of black artists in Boston",
                class: "drawer-image__square"
            },
            {
                src: require("./images/development/narrow/black-artists-narrow.jpg"),
                avifsrc: require("./images/development/narrow/black-artists-narrow.avif"),
                alt: "a mobile mockup showing a web article about the artist Rob Gibbs",
                class: "drawer-image__phone--tilted"
            },
        ]
    },
    {
        uniqueID: "tunnels",
        identifier: "tunnels",
        title: "Interactive Article: Northeastern Tunnels",
        imgsrc: require("./images/development/front/tunnels-front.jpg"),
        imgsrcavif: require("./images/development/front/tunnels-front.avif"),
        imgalt: "Northeastern University Tunnels",
        drawerTitle: "Northeastern Tunnels Story",
        drawerText: "This is an interactive presentation of the tunnels under Northeastern University",
        drawerUrl: "https://news.northeastern.edu/2018/01/16/northeastern-tunnel-system/",
        drawerImages: [
            {
                src: require("./images/development/wide/tunnels-wide.jpg"),
                avifsrc: require("./images/development/wide/tunnels-wide.avif"),
                alt: "webpage layout showing a map overlay",
                class: "drawer-image__wide"
            },
            {
                src: require("./images/development/wide/tunnels-2-wide.jpg"),
                avifsrc: require("./images/development/wide/tunnels-2-wide.avif"),
                alt: "webpage layout showing a map of the Northeastern University tunnels",
                class: "drawer-image__wide"
            },
        ]
    },
    {
        uniqueID: "bees",
        identifier: "bees",
        title: "Interactive Article: Beekeeping Co-op",
        imgsrc: require("./images/development/front/bees-front.jpg"),
        imgsrcavif: require("./images/development/front/bees-front.avif"),
        imgalt: "alt text",
        drawerTitle: "Web Piece: Beekeeping Co-op",
        drawerText: "This is a fun, lighthearted webpage design for a piece about a beekeeping co-op.",
        drawerUrl: "https://news.northeastern.edu/2021/05/19/the-not-so-secret-life-of-bees/",
        drawerImages: [
            {
                src: require("./images/development/wide/bees-wide.jpg"),
                avifsrc: require("./images/development/wide/bees-wide.avif"),
                alt: "alt text",
                class: "drawer-image__wide"
            },
        ]
    },
    {
        uniqueID: "fireworks",
        identifier: "fireworks",
        title: "Interactive Article: Fireworks explainer",
        imgsrc: require("./images/development/front/fireworks-front.jpg"),
        imgsrcavif: require("./images/development/front/fireworks-front.avif"),
        imgalt: "illustration of a firecracker shell",
        drawerTitle: "Fireworks explainer",
        drawerUrl: "https://news.northeastern.edu/2018/07/03/everything-you-want-to-know-about-fireworks-this-fourth-of-july/",
        drawerImages: [
            {
                src: require("./images/development/wide/fireworks-wide.jpg"),
                avifsrc: require("./images/development/wide/fireworks-wide.avif"),
                alt: "alt text",
                class: "drawer-image__wide"
            },
            {
                src: require("./images/development/wide/fireworks-3-wide.jpg"),
                avifsrc: require("./images/development/wide/fireworks-3-wide.avif"),
                alt: "alt text",
                class: "drawer-image__wide"
            },
        ]
    },
    {
        uniqueID: "dataviz",
        identifier: "dataviz",
        title: "Dataviz: Fake News",
        imgsrc: require("./images/development/front/fake-news-front.jpg"),
        imgsrcavif: require("./images/development/front/fake-news-front.avif"),
        imgalt: "a graph showing number of fake news shares based on age",
        drawerTitle: "Fake News Dataviz",
        drawerUrl: "https://news.northeastern.edu/2019/01/24/how-to-stop-the-spread-of-fake-news-for-the-2020-election-campaign/",
        drawerImages: [
            {
                src: require("./images/development/wide/fake-news2-wide.jpg"),
                avifsrc: require("./images/development/wide/fake-news2-wide.avif"),
                alt: "a graph showing number of fake news shares based on age",
                class: "drawer-image__wide"
            },
        ]
    },
];
