import React from 'react';
import {devData} from "../devexamplesdata";
import PortfolioItem from "../components/PortfolioItem";
import IntroPara from "../components/IntroPara";
import CallToAction from "../components/CallToAction";
import { CSSTransition } from 'react-transition-group'
import OutsideClickHandler from "../components/OutsideClickHandler";
import Drawer from '../components/Drawer'

const ComponentActions = {
  None: "None",
  ClickInside: "ClickInside",
  ClickOutside: "ClickOutside",
  FocusInside: "FocusInside",
  FocusOutside: "FocusOutside"
};

class Development extends React.Component {
  curDrawer;

  constructor(props) {
    super(props);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.state = { showDrawer: false, identifier: '', drawerTitle: '', drawerText: '', drawerUrl: '', drawerImages: '', clickStatus: ComponentActions.None, focusStatus: ComponentActions.None};
  }

  toggleDrawer(showDrawer, identifier, drawerTitle, drawerText, drawerUrl, drawerImages) {
    if ((this.state.showDrawer === true) && (this.curDrawer === identifier)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      this.setState({showDrawer: false });
      return;
    }

    if (this.state.showDrawer === false) {
      this.setState({showDrawer: true })
    }
    this.setState({
      identifier: identifier,
      drawerTitle: drawerTitle,
      drawerText: drawerText,
      drawerUrl: drawerUrl,
      drawerImages: drawerImages
    })

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    this.curDrawer = identifier
  }

  closeDrawer = () => {
    this.setState({showDrawer: false })
  }
  render() {
    const identifier = this.state.identifier;
    const drawerTitle = this.state.drawerTitle;
    const drawerText = this.state.drawerText;
    const drawerUrl = this.state.drawerUrl;
    const drawerImages = this.state.drawerImages;
    const showDrawer = this.state.showDrawer;

        return (
            <>
                <main>

                  <IntroPara text="As a front end developer I get to use my creativity to create strong web experiences, most recently in the form of unique digital articles for News@Northeastern. Some of my strengths are HTML5, CSS3, Sass, Javascript/jQuery, Wordpress (gutenberg) and Adobe Creative Suite. I also have experience with React, Svelte, D3.js and Phaser 3." />
                  <OutsideClickHandler
                    onClickOutside={() => {
                      this.closeDrawer();
                    }}
                    onFocusOutside={() => {
                      this.closeDrawer();
                    }}
                  >
                    <CSSTransition
                      in={showDrawer}
                      timeout={{
                        enter: 0,
                        exit: 500
                      }}
                      unmountOnExit
                    >
                      <Drawer
                        identifier={identifier}
                        drawerTitle={drawerTitle}
                        drawerText={drawerText}
                        drawerUrl={drawerUrl}
                        drawerImages={drawerImages}
                        showDrawer={showDrawer}
                        onClose={this.closeDrawer}
                      />
                    </CSSTransition>
                  <div className="grid">
                        {devData.map((data, key) => {
                            return (
                              <PortfolioItem
                                key={data.id}
                                identifier={data.identifier}
                                drawerTitle={data.drawerTitle}
                                drawerText={data.drawerText}
                                drawerUrl={data.drawerUrl}
                                drawerImages={data.drawerImages}
                                onShowDrawerChange={this.toggleDrawer}
                                title={data.title}
                                imgsrc={data.imgsrc}
                                imgalt={data.imgalt}
                                categories={data.categories}
                              />
                            );
                        })}
                    </div>
                  </OutsideClickHandler>
                    <CallToAction
                        ctaPara=""
                        ctaBtnText="Get in touch"
                        ctaUrl="/about"
                    />
                </main>
            </>
        );
    }
};

export default Development;
