export const homeData = [{
    uniqueID: "black-artists",
    identifier: "black-artists",
    title: "Web Piece: Black Artists in Boston",
    imgsrc: require("./images/development/front/black-artists-front.jpg"),
    imgsrcavif: require("./images/development/front/black-artists-front.avif"),
    imgalt: "portraits of black artists in Boston",
    categories: [
        "development",
        "illustration"
    ],
    drawerTitle: "Black Artists",
    drawerText: "Combining illustration and web development to give the user a more immersive experience in this piece focused on black artists in Boston.",
    drawerUrl: "https://news.northeastern.edu/2022/02/09/black-artists-boston-public-art/",
    drawerImages: [
        {
            src: require("./images/development/wide/black-artists-wide.jpg"),
            avifsrc: require("./images/development/wide/black-artists-wide.avif"),
            alt: "map of Boston with a pin indicating where a sculpture by Hank Willis Thomas will be located",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/development/square/black-artists-square.jpg"),
            avifsrc: require("./images/development/square/black-artists-square.avif"),
            alt: "portraits of black artists in Boston",
            class: "drawer-image__square"
        },
        {
            src: require("./images/development/narrow/black-artists-narrow.jpg"),
            avifsrc: require("./images/development/narrow/black-artists-narrow.avif"),
            alt: "a mobile mockup showing a web article about the artist Rob Gibbs",
            class: "drawer-image__phone--tilted"
        },
    ]
},
    {
        uniqueID: "commencement",
        identifier: "commencement",
        title: "Web Piece: Northeastern Commencement",
        imgsrc: require("./images/development/front/commencement-front.jpg"),
        imgsrcavif: require("./images/development/front/commencement-front.avif"),
        imgalt: "Photo collage of Northeastern University Commencement 2022",
        categories: [
            "development"
        ],
        drawerTitle: "Northeastern Commencement 2022",
        drawerText: "I have been a pivotal part of Northeastern University's commencement webpage for the past several years. This once-a-year webpage project requires design and development as well as on-site coding and updates while the event is going on to ensure that more than 4,000 graduates and their families have constant access to information about the event.",
        drawerUrl: "https://news.northeastern.edu/2022/05/12/commencement-2022/",
        drawerImages: [
            {
                src: require("./images/development/wide/commencement-wide.jpg"),
                avifsrc: require("./images/development/wide/commencement-wide.avif"),
                alt: "screenshot of the Northeastern Commencement 2022 webpage",
                class: "drawer-image__wide"
            },
            {
                src: require("./images/development/narrow/commencement-narrow.jpg"),
                avifsrc: require("./images/development/narrow/commencement-narrow.avif"),
                alt: "mobile device mockup of the Northeastern Commencement 2022 webpage",
                class: "drawer-image__phone--tilted"
            },
            {
                src: require("./images/development/narrow/commencement2-narrow.jpg"),
                avifsrc: require("./images/development/narrow/commencement2-narrow.avif"),
                alt: "mobile device mockup of the Northeastern Commencement 2022 webpage",
                class: "drawer-image__phone"
            },
        ]
    },
    {
    uniqueID: "binary",
    identifier: "binary",
    title: "Web Piece: Outside of the Binary",
    imgsrc: require("./images/development/front/binary-portraits-front.jpg"),
    imgsrcavif: require("./images/development/front/binary-portraits-front.avif"),
    imgalt: "Portraits of people who identify as binary",
    categories: [
        "development"
    ],
    drawerTitle: "Outside of the Binary: Northeastern's Gender Non-conforming Community",
    drawerText: "This interactive storytelling project is a portrait series presenting intimate portraits of Northeastern community members who are transgender, nonbinary, and gender nonconforming.",
    drawerUrl: "https://news.northeastern.edu/2021/11/01/trans-gender-and-gender-nonconforming-community-portraits/",
    drawerImages: [
        {
            src: require("./images/development/wide/binary-portraits-wide.jpg"),
            avifsrc: require("./images/development/wide/binary-portraits-wide.avif"),
            alt: "Webpage layout showing text next to a photo of a person",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/development/binary-portrait-movement.gif"),
            alt: "a gif of a person looking up",
            class: "drawer-image__gif"
        },
        {
            src: require("./images/development/narrow/binary-narrow.jpg"),
            avifsrc: require("./images/development/narrow/binary-narrow.avif"),
            alt: "A mobile device layout showing text below a photo of a person",
            class: "drawer-image__phone"
        },
    ]
},
{
    uniqueID: "canvas",
    identifier: "canvas",
    title: "Canvas games",
    imgsrc: require("./images/development/front/grad-game-front.jpg"),
    imgsrcavif: require("./images/development/front/grad-game-front.avif"),
    imgalt: "alt text",
    categories: [
        "development"
    ],
    drawerTitle: "Canvas games",
    drawerText: "",
    drawerImages: [
        {
            src: require("./images/development/wide/pesky-squirrel-2-wide.jpg"),
            avifsrc: require("./images/development/wide/pesky-squirrel-2-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide",
            url: "https://news.northeastern.edu/2019/10/08/play-pesky-squirrels-have-hidden-your-books-on-northeasterns-boston-campus-arboretum/"
        },
        {
            src: require("./images/development/square/grad-game-gameplay-square.jpg"),
            avifsrc: require("./images/development/square/grad-game-gameplay-square.avif"),
            alt: "alt text",
            class: "drawer-image__square",
            url: "https://news.northeastern.edu/2019/05/02/play-this-game-to-see-if-you-can-earn-your-northeastern-university-diploma/"
        },
        {
            src: require("./images/development/square/grad-game-end-square.jpg"),
            avifsrc: require("./images/development/square/grad-game-end-square.avif"),
            alt: "alt text",
            class: "drawer-image__square",
            url: "https://news.northeastern.edu/2019/05/02/play-this-game-to-see-if-you-can-earn-your-northeastern-university-diploma/"
        },
        {
            src: require("./images/development/square/snowflake-square.jpg"),
            avifsrc: require("./images/development/square/snowflake-square.avif"),
            alt: "alt text",
            class: "drawer-image__square",
            url: "https://news.northeastern.edu/2020/12/16/have-some-fun-during-the-new-england-blizzard-while-staying-warm-inside-with-some-snow/"
        },
        {
            src: require("./images/development/narrow/hangman-narrow.jpg"),
            avifsrc: require("./images/development/narrow/hangman-narrow.jpg"),
            alt: "alt text",
            class: "drawer-image__phone--tilted",
            url: "https://news.northeastern.edu/2018/09/12/how-well-do-you-know-northeastern-test-your-knowledge-with-this-interactive-game/"
        },
    ]
},
{
    uniqueID: "cartoon",
    identifier: "cartoon",
    title: "Cartoon Style Editorial Illustration",
    imgsrc: require("./images/illustration/front/physician-burnout-front.jpg"),
    imgsrcavif: require("./images/illustration/front/physician-burnout-front.avif"),
    imgalt: "a female physician is struggling to walk because people are standing on her lab coat",
    categories: [
        "illustration"
    ],
    drawerTitle: "Cartoon Style Editorial Illustration",
    drawerImages: [
        {
            src: require("./images/illustration/wide/physician-burnout-wide.jpg"),
            avifsrc: require("./images/illustration/wide/physician-burnout-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/square/return-to-office-square.jpg"),
            avifsrc: require("./images/illustration/square/return-to-office-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/meditation-square.jpg"),
            avifsrc: require("./images/illustration/square/meditation-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/covid-stress-square.jpg"),
            avifsrc: require("./images/illustration/square/covid-stress-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/wide/mice-wide.jpg"),
            avifsrc: require("./images/illustration/wide/mice-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/wide/wandering-mind-wide.jpg"),
            avifsrc: require("./images/illustration/wide/wandering-mind-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
    ]

},
    {
        uniqueID: "tunnels",
        identifier: "tunnels",
        title: "Web Piece: Northeastern Tunnels",
        imgsrc: require("./images/development/front/tunnels-front.jpg"),
        imgsrcavif: require("./images/development/front/tunnels-front.avif"),
        imgalt: "Northeastern University Tunnels",
        categories: [
            "development"
        ],
        drawerTitle: "Northeastern Tunnels Story",
        drawerText: "This is an interactive presentation of the tunnels under Northeastern University",
        drawerUrl: "https://news.northeastern.edu/2018/01/16/northeastern-tunnel-system/",
        drawerImages: [
            {
                src: require("./images/development/wide/tunnels-wide.jpg"),
                alt: "webpage layout showing a map overlay",
                class: "drawer-image__wide"
            },
            {
                src: require("./images/development/wide/tunnels-2-wide.jpg"),
                avifsrc: require("./images/development/wide/tunnels-2-wide.avif"),
                alt: "webpage layout showing a map of the Northeastern University tunnels",
                class: "drawer-image__wide"
            },
        ]
    },
{
    uniqueID: "realistic",
    identifier: "realistic",
    title: "Realistic Style Editorial Illustration",
    imgsrc: require("./images/illustration/front/WFH-front.jpg"),
    imgsrcavif: require("./images/illustration/front/WFH-front.avif"),
    categories: [
        "illustration"
    ],
    drawerTitle: "Realistic Style Editorial Illustration",
    drawerImages: [
        {
            src: require("./images/illustration/square/WFH-square.jpg"),
            avifsrc: require("./images/illustration/square/WFH-square.avif"),
            alt: "illustration of a woman sitting by a desk wearing a professional jacket and bunny slippers",
            class: "drawer-image__wide"
        },

        {
            src: require("./images/illustration/narrow/ar-narrow.jpg"),
            avifsrc: require("./images/illustration/narrow/ar-narrow.avif"),
            alt: "alt text",
            class: "drawer-image__phone--tilted"
        },
        {
            src: require("./images/illustration/wide/homicide-wide.jpg"),
            avifsrc: require("./images/illustration/wide/homicide-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/wide/cheese-challenge-wide.jpg"),
            avifsrc: require("./images/illustration/wide/cheese-challenge-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
    ]
},
{
    uniqueID: "animals",
    identifier: "animals",
    title: "Baby Animal Series",
    imgsrc: require("./images/development/front/baby-animals-front.jpg"),
    imgsrcavif: require("./images/development/front/baby-animals-front.avif"),
    imgalt: "alt text",
    categories: [
        "illustration"
    ],
    drawerTitle: "Baby Animal Series",
    drawerImages: [
        {
            src: require("./images/illustration/square/giraffe-square.png"),
            avifsrc: require("./images/illustration/square/giraffe-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/deer-square.png"),
            avifsrc: require("./images/illustration/square/deer-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/tiger-square.png"),
            avifsrc: require("./images/illustration/square/tiger-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/bear-square.png"),
            avifsrc: require("./images/illustration/square/bear-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
    ]
},
{
    uniqueID: "watercolor",
    identifier: "watercolor",
    title: "Watercolors",
    imgsrc: require("./images/illustration/front/cactus-front.jpg"),
    imgsrcavif: require("./images/illustration/front/cactus-front.avif"),
    imgalt: "alt text",
    categories: [
        "illustration"
    ],
    drawerTitle: "Watercolors",
    drawerImages: [
        {
            src: require("./images/illustration/square/cactus-square.jpg"),
            avifsrc: require("./images/illustration/square/cactus-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/Nasturtium-square.jpg"),
            avifsrc: require("./images/illustration/square/Nasturtium-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
    ]
},
{
    uniqueID: "bees",
    identifier: "bees",
    title: "Web Piece: Beekeeping Co-op",
    imgsrc: require("./images/development/front/bees-front.jpg"),
    imgsrcavif: require("./images/development/front/bees-front.avif"),
    imgalt: "alt text",
    categories: [
        "development"
    ],
    drawerTitle: "Web Piece: Beekeeping Co-op",
    drawerText: "This is a fun, lighthearted webpage design for a piece about a beekeeping co-op.",
    drawerUrl: "https://news.northeastern.edu/2021/05/19/the-not-so-secret-life-of-bees/",
    drawerImages: [
        {
            src: require("./images/development/wide/bees-wide.jpg"),
            avifsrc: require("./images/development/wide/bees-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
    ]
},
{
    uniqueID: "fireworks",
    identifier: "fireworks",
    title: "Web Piece: Fireworks explainer",
    imgsrc: require("./images/development/front/fireworks-front.jpg"),
    imgsrcavif: require("./images/development/front/fireworks-front.avif"),
    imgalt: "illustration of a firecracker shell",
    categories: [
        "illustration"
    ],
    drawerTitle: "Fireworks explainer",
    drawerUrl: "https://news.northeastern.edu/2018/07/03/everything-you-want-to-know-about-fireworks-this-fourth-of-july/",
    drawerImages: [
        {
            src: require("./images/development/wide/fireworks-wide.jpg"),
            avifsrc: require("./images/development/wide/fireworks-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/development/wide/fireworks-3-wide.jpg"),
            avifsrc: require("./images/development/wide/fireworks-3-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
    ]
},
{
    uniqueID: "vector",
    identifier: "vector",
    title: "Vector based graphics",
    imgsrc: require("./images/illustration/front/penguins-front.jpg"),
    imgsrcavif: require("./images/illustration/front/penguins-front.avif"),
    imgalt: "alt text",
    categories: [
        "illustration"
    ],
    drawerTitle: "Vector based graphics",
    drawerImages: [
        {
            src: require("./images/illustration/square/presidential-candidates-square.jpg"),
            avifsrc: require("./images/illustration/square/presidential-candidates-square.avif"),
            alt: "illustrations of Joe Biden, Bernie Sanders and Donald Trump",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/wide/racoon-wide.jpg"),
            avifsrc: require("./images/illustration/wide/racoon-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/square/turtle-square.jpg"),
            avifsrc: require("./images/illustration/square/turtle-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/penguins-square.jpg"),
            avifsrc: require("./images/illustration/square/penguins-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },

    ]
}
];
