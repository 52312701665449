import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import "./index.css";
import App from "./App";
import ErrorPage from './routes/ErrorPage'
import Illustration from './routes/Illustration'
import Development from './routes/Development'
import About from './routes/About'
import Home from './routes/Home'
import { CSSTransition } from 'react-transition-group'
// import * as serviceWorker from "./serviceWorker";

// Placed the context provider here so that <App/> can call useLocation()
const Root = () => <BrowserRouter><App /></BrowserRouter>;

ReactDOM.render(
	<BrowserRouter>
		<Routes>
			<Route path="*" element={<ErrorPage />} />
			<Route exact path="/" element={<App />}>
				<Route
					index
					element={<Home />}
				/>
				<Route path="illustration" element={<Illustration />} />
				<Route path="development" element={<Development />} />
				{/*<Route exact path="work-history" element={<WorkHistory />} />*/}
				<Route path="about" element={<About />} />
			</Route>
		</Routes>
	</BrowserRouter>,
    document.getElementById("root")
);


