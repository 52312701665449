import React from "react";

function Footer(props) {
    return (
        <footer className="footer">
            &copy; {props.year} Hannah Moore
        </footer>
    )
}

export default Footer
