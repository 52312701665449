export const illustrationData = [{
    uniqueID: "cartoon",
    identifier: "cartoon",
    title: "Cartoon Style Editorial Illustration",
    imgsrc: require("./images/illustration/front/physician-burnout-front.jpg"),
    imgsrcavif: require("./images/illustration/front/physician-burnout-front.avif"),
    imgalt: "a female physician is struggling to walk because people are standing on her lab coat",
    drawerTitle: "Cartoon Style Editorial Illustration",
    drawerImages: [
        {
            src: require("./images/illustration/wide/physician-burnout-wide.jpg"),
            avifsrc: require("./images/illustration/wide/physician-burnout-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/square/return-to-office-square.jpg"),
            avifsrc: require("./images/illustration/square/return-to-office-square.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/square/meditation-square.jpg"),
            avifsrc: require("./images/illustration/square/meditation-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/square/covid-stress-square.jpg"),
            avifsrc: require("./images/illustration/square/covid-stress-square.avif"),
            alt: "alt text",
            class: "drawer-image__square"
        },
        {
            src: require("./images/illustration/wide/mice-wide.jpg"),
            avifsrc: require("./images/illustration/wide/mice-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
        {
            src: require("./images/illustration/wide/wandering-mind-wide.jpg"),
            avifsrc: require("./images/illustration/wide/wandering-mind-wide.avif"),
            alt: "alt text",
            class: "drawer-image__wide"
        },
    ]

    },
    {
        uniqueID: "alice",
        identifier: "alice",
        title: "Alice's Sweet Landing",
        imgsrc: require("./images/illustration/front/alice-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/alice-square-front.jpg"),
        imgalt: "alt",
        drawerTitle: "Alice's Sweet Landing",
        drawerImages: [
            {
                src: require("./images/illustration/square/alice-square.jpg"),
                avifsrc: require("./images/illustration/square/alice-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "3",
        identifier: "3",
        title: "Balcony",
        imgsrc: require("./images/illustration/front/balcony-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/balcony-square-front.avif"),
        imgalt: "alt",
        drawerTitle: "Balcony",
        drawerImages: [
            {
                src: require("./images/illustration/square/balcony-square.jpg"),
                avifsrc: require("./images/illustration/square/bear-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "4",
        identifier: "4",
        title: "Birds",
        imgsrc: require("./images/illustration/front/birds-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/birds-square-front.avif"),
        imgalt: "alt",
        drawerTitle: "Birds",
        drawerImages: [
            {
                src: require("./images/illustration/square/birds-square.jpg"),
                avifsrc: require("./images/illustration/square/birds-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "5",
        identifier: "5",
        title: "Holiday Reading",
        imgsrc: require("./images/illustration/front/books-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/books-square-front.avif"),
        imgalt: "alt",
        drawerTitle: "Holiday Reading",
        drawerImages: [
            {
                src: require("./images/illustration/wide/books-wide.jpg"),
                avifsrc: require("./images/illustration/wide/books-wide.avif"),
                alt: "alt text",
                class: "drawer-image__wide"
            },
        ]

    },
    {
        uniqueID: "6",
        identifier: "6",
        title: "Moon and Clouds",
        imgsrc: require("./images/illustration/front/clouds-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/clouds-square-front.avif"),
        imgalt: "alt",
        drawerTitle: "Moon and Clouds",
        drawerImages: [
            {
                src: require("./images/illustration/square/clouds-square.jpg"),
                avifsrc: require("./images/illustration/square/clouds-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "7",
        identifier: "7",
        title: "Cozy Day",
        imgsrc: require("./images/illustration/front/coze-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/coze-square-front.avif"),
        imgalt: "Cozy Day",
        drawerTitle: "Cozy Day",
        drawerImages: [
            {
                src: require("./images/illustration/square/coze-square.jpg"),
                avifsrc: require("./images/illustration/square/coze-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "8",
        identifier: "8",
        title: "Emil",
        imgsrc: require("./images/illustration/front/emil-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/emil-square-front.avif"),
        imgalt: "Emil",
        drawerTitle: "Emil",
        drawerImages: [
            {
                src: require("./images/illustration/square/emil-square.jpg"),
                avifsrc: require("./images/illustration/square/emil-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "9",
        identifier: "9",
        title: "Fish",
        imgsrc: require("./images/illustration/front/fish-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/fish-square-front.avif"),
        imgalt: "Fish",
        drawerTitle: "Fish",
        drawerImages: [
            {
                src: require("./images/illustration/square/fish-square.jpg"),
                avifsrc: require("./images/illustration/square/fish-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "10",
        identifier: "10",
        title: "Flowers and Citrus",
        imgsrc: require("./images/illustration/front/flowerdress-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/flowerdress-square-front.avif"),
        imgalt: "Flowers and Citrus",
        drawerTitle: "Flowers and Citrus",
        drawerImages: [
            {
                src: require("./images/illustration/square/flowerdress-square.jpg"),
                avifsrc: require("./images/illustration/square/flowerdress-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "11",
        identifier: "11",
        title: "Frogs",
        imgsrc: require("./images/illustration/front/frogs-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/frogs-square-front.avif"),
        imgalt: "Frogs",
        drawerTitle: "Frogs",
        drawerImages: [
            {
                src: require("./images/illustration/wide/frogs-wide.jpg"),
                avifsrc: require("./images/illustration/wide/frogs-wide.avif"),
                alt: "alt text",
                class: "drawer-image__wide"
            },
        ]

    },
    {
        uniqueID: "12",
        identifier: "12",
        title: "Gardening",
        imgsrc: require("./images/illustration/front/gardening-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/gardening-square-front.avif"),
        imgalt: "Gardening",
        drawerTitle: "Gardening",
        drawerImages: [
            {
                src: require("./images/illustration/square/gardening-square.jpg"),
                avifsrc: require("./images/illustration/square/gardening-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "13",
        identifier: "13",
        title: "Ice Cream",
        imgsrc: require("./images/illustration/front/icecream-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/icecream-square-front.avif"),
        imgalt: "Ice Cream",
        drawerTitle: "Ice Cream",
        drawerImages: [
            {
                src: require("./images/illustration/square/icecream-square.jpg"),
                avifsrc: require("./images/illustration/square/icecream-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "14",
        identifier: "14",
        title: "Nyhavn",
        imgsrc: require("./images/illustration/front/nyhavn-front.jpg"),
        imgsrcavif: require("./images/illustration/front/nyhavn-front.avif"),
        imgalt: "Nyhavn",
        drawerTitle: "Nyhavn",
        drawerImages: [
            {
                src: require("./images/illustration/square/nyhavn-square.jpg"),
                avifsrc: require("./images/illustration/square/nyhavn-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "15",
        identifier: "15",
        title: "Quilt",
        imgsrc: require("./images/illustration/front/quilt-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/quilt-square-front.avif"),
        imgalt: "Quilt",
        drawerTitle: "Quilt",
        drawerImages: [
            {
                src: require("./images/illustration/square/quilt-square.jpg"),
                avifsrc: require("./images/illustration/square/quilt-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "16",
        identifier: "16",
        title: "Skater",
        imgsrc: require("./images/illustration/front/skate-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/skate-square-front.avif"),
        imgalt: "Skater",
        drawerTitle: "Skater",
        drawerImages: [
            {
                src: require("./images/illustration/square/skate-square.jpg"),
                avifsrc: require("./images/illustration/square/skate-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "17",
        identifier: "17",
        title: "Starry",
        imgsrc: require("./images/illustration/front/starry-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/starry-square-front.avif"),
        imgalt: "Starry",
        drawerTitle: "Starry",
        drawerImages: [
            {
                src: require("./images/illustration/square/starry-square.jpg"),
                avifsrc: require("./images/illustration/square/starry-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
    {
        uniqueID: "11",
        identifier: "11",
        title: "Sunflower",
        imgsrc: require("./images/illustration/front/sunflower-square-front.jpg"),
        imgsrcavif: require("./images/illustration/front/sunflower-square-front.avif"),
        imgalt: "Sunflower",
        drawerTitle: "Sunflower",
        drawerImages: [
            {
                src: require("./images/illustration/square/sunflower-square.jpg"),
                avifsrc: require("./images/illustration/square/sunflower-square.avif"),
                alt: "alt text",
                class: "drawer-image__square"
            },
        ]

    },
];
