import React from "react";
import classnames from 'classnames';

function IntroPara(props) {
    const {text, styleEmphasize} = props;
    const classes = classnames(
        'intro__text',
        { 'intro__text--emphasize': styleEmphasize }
    );

    return (
        <div className="intro">
            <p className={classes}>
                {text}
            </p>
        </div>
    )
}


export default IntroPara
