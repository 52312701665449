import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import {ExternalLink} from "react-external-link";

function Drawer(props) {
  let images = props.drawerImages.map(function(image) {
    if (image.url) {
      return (
        <ExternalLink className={`drawer-image ${image.class}`} href={image.url} target="_blank">
          <picture>
            {image.avifsrc ?
            <source srcSet={image.avifsrc} type="image/avif "/>
              : null}
            <img loading="lazy" alt={image.alt} src={image.src}/>
          </picture>
        </ExternalLink>
      )
    } else {
      return (
          <picture className={`drawer-image ${image.class}`}>
            <source srcSet={image.avifsrc} type="image/avif "/>
            <img alt={image.alt} src={image.src} />
          </picture>
      )
    }
  })
        return (

                <div className={`drawer ${props.showDrawer ? 'show' : ''} ${props.identifier}`}>
                    <button className="drawer-close" onClick={props.onClose}><FontAwesomeIcon icon={faXmark} /></button>
                    <div className="drawer-content">
                        <h2 className="drawer-title">{props.drawerTitle}</h2>
                        {props.drawerText ?
                        <p className="drawer-text">{props.drawerText}</p> : null}
                        {props.drawerImages ?
                          <div className="drawer-image-container">
                            {images}
                        </div>
                          :null}
                        {!props.drawerUrl ? null : <ExternalLink className="drawer-link" href={props.drawerUrl}
                                                                     target="_blank">View full project</ExternalLink>}
                    </div>
                </div>
        )
}

export default Drawer
