import React from 'react';

function ErrorPage() {
    return (
        <>
            <main>
                <h2>Error</h2>
                <p>
                    error error
                </p>
            </main>
        </>
    );
}

export default ErrorPage;
