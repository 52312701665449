import React from 'react';

export default class OutsideClickHandler extends React.Component {
    clickCaptured = false;
    focusCaptured = false;

    renderComponent() {
        return React.createElement(
            this.props.component || 'div',
                this.getProps(),
                this.props.children
        )
    }

    getProps() {
        return {
            onMouseDown: this.innerClick,
            onTouchStart: this.innerClick,
            onFocus: this.innerFocus,
            className: 'outside-click-wrapper'
    };
    }

    innerClick = () => {
        this.clickCaptured = true;
    }

    innerFocus = () => {
        this.focusCaptured = true;
    }

    componentDidMount() {
        document.body.addEventListener('mousedown', this.documentClick);
        document.body.addEventListener('touchstart', this.documentClick);
        document.body.addEventListener('focusin', this.documentFocus);
    }

    componentWillUnmount() {
        document.body.removeEventListener('mousedown', this.documentClick);
        document.body.removeEventListener('touchstart', this.documentClick);
        document.body.removeEventListener('focusin', this.documentFocus);
    }

    documentClick = (event) => {
        if (!this.clickCaptured && this.props.onClickOutside) {
            this.props.onClickOutside(event);
        }
        this.clickCaptured = false;
    }

    documentFocus = (event) => {
        if (!this.focusCaptured && this.props.onFocusOutside) {
            this.props.onFocusOutside(event);
        }
        this.focusCaptured = false;
    }

    render() {
        if (typeof this.props.children === 'function') {
            return this.props.children(this.getProps())
        }

        return this.renderComponent()
    }
}
