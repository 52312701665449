// import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import React, {Component} from "react";
import DesktopNav from "./DesktopNav";
import classnames from "classnames";

class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = { mobileView: true, mobileMenuOpen: false, language: '' };
    }

    handleLanguage = (langValue) => {
        this.setState({language: langValue});
    }

    updateDimensions = () => {
        if(window.innerWidth > 500) {
            this.setState({mobileView: false })
        } else {
            this.setState({mobileView: true})
        }
    }

    componentDidMount() {
        this.updateDimensions();
        // this.closeMobileMenu();
        window.addEventListener("resize", this.updateDimensions)
    }

    handleNavLinkClick = () => {

        this.setState(prevState => ({
            mobileToggle: !prevState.mobileToggle
        }))
    }
    render() {


        const hamburgerClassNames = classnames(
            'hamburger',
            { 'hamburger--active': this.state.mobileToggle },
        );

        return (
            <nav className="nav">
                <button className={hamburgerClassNames} onClick={this.handleNavLinkClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                <DesktopNav />
                <MobileNav mobileToggle={this.props.mobileToggle}
                           onClickNavLink={this.handleNavLinkClick}
                           mobileView={this.state.mobileView}
                />
            </nav>
        );
    }
}

export default Nav;
